<template>
  <div id="vm-cola-login" class="vm-cola-login">
    <div class="login-container">
      <template v-if="is_register">
        <div class="login-container__register">
          <h2>Daftar akun SOCO</h2>
          <h3>
            Scan QR code & buat akun di SOCO App <br />
            untuk dapatkan EXTRA SOCO Points!
          </h3>

          <div class="qrcode">
            <qrcode-vue :value="registerQRcode" :size="355" level="H"></qrcode-vue>
            <!-- <img width="350" height="350" src="/static/img/vm-cola/register-qrcode.jpeg" alt="register-qrcode" /> -->
          </div>
        </div>

        <div class="login-container__actions">
          <p class="label-have-account">Sudah punya akun SOCO?</p>

          <div :class="{ 'play-games': isVendingMachine }" class="action-container">
            <div
              class="have-account"
              @click="
                is_register = false;
                is_login = true;
              "
            >
              Masuk sekarang
            </div>
            <div v-if="!isVendingMachine" class="as-guest" @click.prevent="continueAsGuest()">Lanjut sebagai tamu</div>
          </div>
        </div>
      </template>
      <template v-else-if="is_login">
        <div class="login-container__login">
          <h2>Scan untuk login</h2>
          <h3>Scan QR dengan SOCO App <br />untuk lanjut belanja</h3>

          <div class="qrcode">
            <qrcode-vue :value="QRcode" :size="355" level="H"></qrcode-vue>
          </div>
        </div>

        <div class="login-container__actions">
          <p class="label-have-account">Belum punya akun SOCO?</p>

          <div :class="{ 'play-games': isVendingMachine }" class="action-container">
            <div
              class="have-account"
              @click="
                is_register = true;
                is_login = false;
              "
            >
              Daftar sekarang
            </div>
            <div v-if="!isVendingMachine" class="as-guest" @click.prevent="continueAsGuest()">Lanjut sebagai tamu</div>
          </div>
        </div>
      </template>
    </div>

    <GModal
      v-show="login_status > -1 && user && !isVendingMachine && vendingMachineDevice !== 'true'"
      class="modal--full-fixed"
      :class="{ failed: login_status == 0 }"
    >
      <div slot="header"></div>
      <div slot="body" ref="modalLogin" class="content" :class="{ failed: login_status == 0 }">
        <template v-if="login_status == 0">
          <img width="246.56" height="202.28" src="/static/img/vending-machine/login-failed-new.svg" alt="" />
          <h4 class="name failed">Akun tidak ditemukan</h4>
          <h5 class="desc failed">Yuk coba lagi dengan <br />scan ulang!</h5>
          <button class="scan-ulang" @click.prevent="closeStatus()">Scan ulang</button>
          <button class="batalkan" @click.prevent="cancelRescan()">Batalkan</button>
        </template>
        <template v-else>
          <figure v-if="user && user.image">
            <img :src="user.image" :alt="user.name" />
          </figure>
          <h4 v-if="user && user.name" class="name">Hi, {{ user.name }}!</h4>
          <h5 class="desc">
            Yuk, lanjut ke pembayaran untuk
            <br />
            dapatkan beauties incaranmu!
          </h5>

          <div class="continue-shopping" @click="goToOrder">
            <div class="continue-shopping__wrapper">
              <span>Lanjut ke keranjang</span>
            </div>
          </div>
          <div class="logout" @click="logout">
            <span>Keluar</span>
          </div>
        </template>
      </div>
    </GModal>

    <GModal
      v-show="login_status > -1 && user && isVendingMachine && vendingMachineDevice === 'false'"
      class="modal--full-fixed"
    >
      <div slot="header"></div>
      <div slot="body" ref="modalLogin" class="content" :class="{ failed: login_status == 0 }">
        <template v-if="login_status == 0">
          <h4 class="name failed">Account not found</h4>
          <h5 class="desc failed">Maaf, akun tidak ditemukan. <br />Silakan coba lagi!</h5>
          <img src="/static/img/vending-machine/login-failed-new.svg" alt="" />
          <button @click.prevent="closeStatus()">COBA SCAN LAGI</button>
        </template>
        <template v-else>
          <figure v-if="user && user.image">
            <img :src="user.image" :alt="user.name" />
          </figure>
          <h4 v-if="user && user.name" class="name">Hi, {{ user.name }}!</h4>
          <h5 class="desc">
            Selamat data di <b>Sociolla</b>
            <br />
            <b>Smart Vending Machine.</b>
            <br />
            Siap untuk dapatkan
            <br />
            EXTRA TREAT?
          </h5>

          <div class="continue-shopping" @click="goToPlayGameAfterLogin">
            <div class="continue-shopping__wrapper">
              <span>PLAY & WIN NOW</span>
            </div>
          </div>
          <div class="logout" @click="logout">
            <span>LOG OUT</span>
          </div>
        </template>
      </div>
    </GModal>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue';
import SocketIO from 'socket.io-client';
import helpers from '@/mixins/helpers';
import autotimeout from '@/mixins/auto-timeout';

const Config = require('~/config/default.env').default;

const QR_CODE_LIFE_TIME = 10 * 60 * 1000;
// const GET_TOKEN_INTERVAL = 5 * 1000;
const socket = SocketIO(`${Config.MS_SOCKET_API_URL}/store`, { transports: ['websocket'] });

export default {
  name: 'vmColaLogin',
  components: {
    QrcodeVue,
  },
  mixins: [helpers, autotimeout],
  data() {
    return {
      qrGenTimer: null,
      getTokenTimer: null,
      loading_token: false,

      is_register: false,
      is_login: true,
      is_guest: false,
    };
  },
  computed: {
    registerQRcode() {
      return 'https://sociolla.app.link/registervm';
    },

    QRcode() {
      const QRCodeValue = this.$store.state.QRcode.value || '';
      return QRCodeValue;

      // if (this.is_development) {
      //   return 'https://sociolla.test-app.link?action=login&value=' + QRCodeValue;
      // }

      // return 'https://sociolla.app.link?action=login&value=' + QRCodeValue;
    },
    is_development() {
      return Config.NODE_ENV === 'development';
    },
    login_status() {
      return this.$store.state.QRcode.login_status;
    },
    user() {
      return this.$store.state.QRcode.user;
    },
    authToken() {
      return this.$store.state.QRcode.authToken;
    },
    isVendingMachine() {
      return this.$route.name === 'vendingMachineLogin';
    },
    playGameAfterLoginUrl() {
      if (this.$route.query.isvn) {
        return '/vending-machine-vn/pimple-pop?isvn=true';
      } else {
        return this.$store.state.vm.urlVm ? this.$store.state.vm.urlVm : '/vending-machine/pimple-pop';
      }
    },
    already_send_cart() {
      return this.$store.state.QRcode.already_send_cart;
    },
    vendingMachineDevice() {
      return Cookies.get('vending_machine_device');
    },
  },

  created() {
    Cookies.set('token', '', { expires: 0 });
    this.$store.dispatch('generateQrCode');

    // re-generate qr code every 10 minutes
    this.qrGenTimer = setInterval(() => {
      if (this.$store.state.QRcode.isLoggedIn) {
        // stop re-generation after logging in
        return clearInterval(this.qrGenTimer);
      }
      this.$store.dispatch('generateQrCode');
    }, QR_CODE_LIFE_TIME);

    const self = this;

    socket.on('qr_code_login', async (data) => {
      if (this.isVendingMachine && self.$store.state.QRcode.value == data.qr_code) {
        Cookies.set('token', data.token, { expires: 1 });
        self.$store.commit('authToken', data.token);
        self.$store.dispatch('getToken', { data: self });
        // socket.off('qr_code_login');
        return;
      }

      if (this.$store.state.QRcode.isLoggedIn) {
        await Promise.all([
          socket.off('qr_code_login'),
          localStorage.removeItem('is_guest'),
          localStorage.removeItem('guest_cart_id'),
          this.$store.commit('SET_GLOBAL_LOADING', false),
        ]);

        this.$router.push('/vmcola/order');
        // socket.off('qr_code_login');
      } else if (this.$store.state.QRcode.value == data.qr_code && !this.loading_token) {
        this.loading_token = true;
        this.$store.commit('SET_GLOBAL_LOADING', true);
        Cookies.set('token', data.token, { expires: 1 });
        this.$store.commit('authToken', data.token);

        this.$store
          .dispatch('getToken', {
            redirect: '/vmcola/order',
            data: this,
            is_vmcola: true,
            direct_to_order: !!this.$store.state.QRcode.isLoggedIn && this.$store.state.QRcode.login_status > -1,
          })
          .then((res) => {
            localStorage.removeItem('is_guest');
            localStorage.removeItem('guest_cart_id');
            this.$store.commit('SET_GLOBAL_LOADING', false);
          })
          .catch((err) => {
            this.$store.commit('SET_GLOBAL_LOADING', false);
            console.log('Error User: ', err);
          });
        // socket.off('qr_code_login');
      }
    });
  },
  destroyed() {
    clearInterval(this.qrGenTimer);
    this.loading_token = false;
    this.$store.commit('loginStatus', -1);
  },
  beforeDestroy() {
    this.loading_token = false;
    this.$store.commit('SET_GLOBAL_LOADING', false);
    socket.off('qr_code_login');
  },
  methods: {
    cancelRescan() {
      this.$store.commit('loginStatus', -1);
    },
    logout() {
      this.$store.dispatch('logout', this.isVendingMachine ? '/vmcola/home' : '/vmcola');
    },
    goToOrder() {
      this.$router.push('/vmcola/order');
    },
    goToPlayGameAfterLogin() {
      this.$router.push(this.playGameAfterLoginUrl);
    },
    goToShopping() {
      this.$router.push('/vmcola/products');
    },
    closeStatus() {
      this.loading_token = false;

      Promise.all([
        this.$store.dispatch('generateQrCode'),
        this.$store.commit('loginStatus', -1),
        this.$store.commit('loggedOut'),
      ]);
    },
    async continueAsGuest() {
      if (this.already_send_cart) {
        await this.removeProducts();
      }

      await Promise.all([
        this.$store.commit('SET_GLOBAL_LOADING', true),
        this.$store.commit('SET_ALREADY_SEND_CART', false),
        this.$store
          .dispatch('sendCartData', {
            redirect: '/vmcola/order',
            data: this,
            is_vmcola: true,
            is_guest: true,
          })
          .then((res) => {
            setTimeout(() => {
              this.$store.commit('SET_GLOBAL_LOADING', false);
              this.is_guest = true;
            }, 1500);
          })
          .catch((err) => {
            console.log('Error Guest: ', err);
          }),
      ]);

      this.goToOrder();
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'login';
</style>
